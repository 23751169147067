@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@100;300;400;500;600&display=swap');

:root {
	--main-color  : #f3f7fb;
	--second-color: #ffffff;
	--threed-color: #177ee5;
	--black-color : #112950;
}

body {
	margin                     : 0;
	font-family                : "Epilogue", sans-serif;
	font-size                  : 1rem;
	font-weight                : 400;
	line-height                : 1.5;
	color                      : #828ca5;
	background-color           : #fff;
	-webkit-text-size-adjust   : 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	padding                    : 0px !important;
	position                   : relative;
	overflow-x                 : hidden;
	overflow-y                 : scroll;

}

::-webkit-scrollbar {
	width             : 12px;
	background-color  : #F5F5F5;
	-webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, .2);
	border-radius     : 10px;
}

::-webkit-scrollbar-thumb {
	border-radius   : 10px;
	background-color: var(--threed-color);
}

hr {
	margin          : 0 0;
	color           : inherit;
	background-color: currentColor;
	border          : 0;
	opacity         : 0.25;
}

hr:not([size]) {
	height: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin-top   : 0;
	margin-bottom: 0.5rem;
	font-weight  : 500;
	line-height  : 1.2;
	color        : var(--black-color)
}

h1,
.h1 {
	font-size: calc(1.525rem + 3.3vw);
}

i:hover {
	transition: 0.2s ease;
	color     : var(--threed-color);

}

@media (min-width: 1200px) {

	h1,
	.h1 {
		font-size: 4rem;
	}
}

h2,
.h2 {
	font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {

	h2,
	.h2 {
		font-size: 2.25rem;
	}
}

h3,
.h3 {
	font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {

	h3,
	.h3 {
		font-size: 1.5rem;
	}
}

h4,
.h4 {
	font-size: 1.25rem;
}

h5,
.h5 {
	font-size: 1.125rem;
}

h6,
.h6 {
	font-size: 1rem;
}

p {
	margin-top   : 0;
	margin-bottom: 1rem;
}



code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

img {
	display  : inline-block;
	max-width: 100%;
	height   : auto;
}

img,
svg {
	vertical-align: middle;
}