.applications-h2{
    display: flex;
    font-weight: 600;
    justify-content: center;
    font-size: 42px;
}
@media(max-width:1199.98px){
    .applications-h2{
        font-size: 33.6px;
    }
}
@media(max-width:991.98px){
    .applications-h2{
        font-size: 26px;
    }
}
