.contacts-title {
    font-size  : 31.5px;
    color      : var(--black-color);
    font-weight: 600;
}

.contact-adress {
    font-size: 14px;
    color    : #212529;
    margin   : 24px 0 0;
}

.divider {
    display    : block;
    font-size  : 0;
    line-height: 0;
}

.divider:before {
    display      : inline-block;
    width        : 100%;
    margin-top   : 0;
    margin-bottom: 0;
    content      : "";
    border-top   : 1px solid #e1e7ed;
}

.link {
    text-decoration: underline;
    color          : var(--threed-color);
    font-size      : 14px;
    margin         : 25px 0 0;
    margin-bottom: 12px;
}

/* .social {
    margin: 22.05px 0px 20px -0.6rem;
} */

.icon {
    display    : inline-flex;
    line-height: 1;
    font-size  : 29px;
    color      : var(--black-color);
}

/* ! Form Bölümü */
.form-box {
    box-sizing      : border-box;
    padding         : 35px 35px;
    background-color: #fff;
}

.form-label {
    margin-bottom: 0.5rem;
    padding-top  : 1.2rem;
    font-size    : 12.25px;
}


input {
    border-bottom: 2px solid var(--black-color);
}

.form-control {
    text-overflow      : ellipsis;
    display            : block;
    width              : 100%;
    padding            : 0.75rem 1.25rem;
    font-size          : 0.92rem;
    font-weight        : 400;
    line-height        : 1.5;
    color              : #828ca5;
    background-color   : #f3f7fb;
    background-clip    : padding-box;
    border             : 1px solid #f3f7fb;
    border-bottom-color: var(--black-color);

    appearance   : none;
    border-radius: 0;
    transition   : border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::placeholder {
    color: #828ca5;

}

.form-control:focus {
    box-shadow: none;
}

.custom-control-label {
    padding-left: 2.1875rem;
    font-size   : 0.875rem;
    color       : #828ca5;
}

.btn-lg,
.btn-group-lg>.btn {
    padding      : 1.4375rem 1.75rem;
    font-size    : 0.9375rem;
    border-radius: 0.625rem;
}

.btn-block {
    display        : flex;
    justify-content: center;
    width          : 100%;
}

.btn {
    margin-top    : 2rem;
    position      : relative;
    z-index       : 0;
    display       : inline-block;
    text-align    : center;
    border        : 2px solid transparent;
    font-weight   : 500;
    letter-spacing: -.01em;
    color         : #fff;
    background    : #177ee5;
    transition    : all 0.2s ease-in-out;
    overflow      : hidden;
    will-change   : transform;
    padding       : 1.2rem;
    font-size     : 0.9375rem;
    border-radius : 0.625rem;
}


.btn:focus,
.btn.focus {
    outline: 0;
}

.btn:hover,
.btn.hover,
.btn:active,
.btn.active {
    text-decoration: none;
    color          : #fff;
    background     : #1265b7;
    border-color   : #112950;
}

.btn-lg,
.btn-group-lg>.btn {
    padding      : 1.4375rem 1.75rem;
    font-size    : 0.9375rem;
    border-radius: 0.625rem;
}

.btn-block {
    display        : flex;
    justify-content: center;
    width          : 100%;
}

.btn {
    position      : relative;
    z-index       : 0;
    display       : inline-block;
    text-align    : center;
    border        : 2px solid transparent;
    font-weight   : 500;
    letter-spacing: -.01em;
    color         : #fff;
    background    : #177ee5;
    transition    : all 0.2s ease-in-out;
    overflow      : hidden;
    will-change   : transform;
    padding       : 0.6875rem 0.875rem;
    font-size     : 0.9375rem;
    border-radius : 0.625rem;
    padding-top   : 0.875rem;
}

.btn:hover::after,
.btn.hover::after,
.btn:active::after,
.btn.active::after {
    transform: translate(-50%, -50%) scale(1);
}

.btn::after {
    position      : absolute;
    left          : 50%;
    top           : 50%;
    z-index       : -1;
    padding-left  : 150%;
    padding-bottom: 150%;
    content       : '';
    background    : #112950;
    transform     : translate(-50%, -50%) scale(0);
    transition    : .5s;
    border-radius : 50%;
}
.test{
    padding-bottom: 24px;
}
.tel-contact-wrapper{
    display: flex;
}
@media (max-width: 1199.98px) {
    .contacts-title {
        font-size: 25px;
    }

    .contact-adress {
        font-size  : 16px;
        font-weight: 300;
    }
}
@media (max-width: 767.98px) {

    .contact-link-number{
        margin-right: 21px
    }
    .concat-tel-body{
        box-sizing: border-box;
        padding: 14px 0 0;
        margin-left: 16px;
    }
}
@media (max-width: 575.98px) {
    .concat-tel-body{
        padding: 7px 0 0;
    }
}

