li.sidebarActive a{
  background-color: #177EE5;
  
}
li.sidebarActive a .sidebar-title {
  color: white;
}
.sidebar-length{
  color: rgb(228, 228, 228) !important;
}

#combobox {
  display         : none !important;
  padding         : 20px;
  background-color: #fff;
  position        : relative;
}

/*! devamını oku kısmımızdır ... */
.truncate {
  overflow          : hidden;
  text-overflow     : ellipsis;
  display           : -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.expanded {
  overflow     : visible !important;
  text-overflow: initial !important;
  white-space  : initial !important;
}

.read-more {
  cursor    : pointer;
  padding   : 4px 0 0;
  margin    : 0;
  color     : #007bff;
  transition: 0.2s ease;
}

.read-more:hover {
  color: #112950;
}

.read-more i:hover {
  color: #112950;
}

.all-description {
  transition: 0.3s ease !important;
}

#sidebar {
  border-radius      : 5px;
  /* margin-top      : 2rem; */
  min-width          : 250px;
  width              : 350px;
  max-height         : calc(100vh - 250px);
  background-color   : transparent;
  padding-left       : 0;
  padding-right      : 0;
  overflow-y         : scroll;
  position           : sticky;
  top                : 140px;
}

#sidebar li {
  border-bottom: 1px solid #828ca51f;
}

#sidebar li a {
  color: #828ca5;
}

#sidebar li a:hover {
  color: #177ee5e1;
}

.allVideos {
  padding    : 0;
  width      : 112px;
  font-weight: 600;
  color      : var(--threed-color);
  font-size  : 16px;
}

#allVideos {
  width           : 100% !important;
  background-color: var(--main-color);
  /* position: relative; */
}

#videos-text-container,
#videos-container {
  width: 100%;
}

.videos-section {
  min-height      : calc(100vh - 90px);
  min-width       : 320px;
  max-width       : calc(100% - 350px);
  background-color: var(--main-color);
  padding-top     : 0;
  position        : relative;
}

.circleIcon {
  font-size: 0.5rem;
  position : relative;
  bottom   : 0.1rem;
}
.all-video-fluid{
  padding-top: 48px;
}
.back-top-div{
  z-index: 999999 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 8px;
  padding: 1rem;
  background: linear-gradient(135deg, #126dc9c2,#0c4d8d);
  color: white;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  border: 2px solid white;
  transition: 0.3s ease;
}
.search-col-div{
  padding: 0 24px;
}
@media (max-width: 1399.98px) {
.all-video-fluid{
  padding: 48px 24px 0;
}
.search-col-div{
  padding-right: 36px;
}

}
@media (max-width: 1199.98px) {
  #sidebar {
    width: 250px;
  }
  .videos-section {
    max-width: initial;
  }
  .sidebar {
    position: absolute;
    top     : 80px;
  }
  .back-top-div{
    padding: 13px !important;
  }
 .videos-section{
  min-width: 680px;
 }
 .all-video-fluid{
  padding: 48px 12px 0;
}
.search-col-div{
  padding: 0 24px;
}

}

@media (max-width: 991.98px) {
  .allVideos-container {
    width: 696px !important;
  }
  .services-title{
    text-align: center;
    font-size: 36px;
  }
  .all-video-fluid{
    padding-top: 20px;
  }
  .videos-section {
    margin-top: 0rem;
  }
  .allVideos-container {
    width: 100%;
  }
  #sidebar {
    display: none;
  }

  #sidebar ul {
    display: none;
  }
  #combobox {
    display: block !important;
  }
}

@media (max-width: 767.98px) {
  .allVideos-container {
    width: 516px !important;
  }
  .services-title{
    text-align: center;
    font-size: 32px;
  }
  .back-top-div{
    padding: 12px !important;
  }
  .videos-section{
    min-width: 180px;
   }
}
@media (max-width: 575.98px) {
  .allVideos-container {
    width:549px !important;
  }
  .services-title{
    padding-left: 0 !important;
  }
  .section-write{
    font-size: 16px !important;
  }
}
@media (max-width: 565.98px) {
  .allVideos-container {
    width:539px !important;
  }

}
@media (max-width: 555.98px) {
  .allVideos-container {
    width:529px !important;
  }
}
@media (max-width: 554.98px) {
  .allVideos-container {
    max-width:calc(100% + 24px) !important;
  }
}
@media (max-width: 474.98px) {
    .info-box i {
    font-size: 20px !important;
  }
  .section-write{
    font-size: 15px !important;
  }
}
@media (max-width: 344.98px) {
  .search-col-div{
    padding: 0 24px;
  }
}
@media (max-width: 340.98px) {
  .search-col-div{
    padding: 0 20px;
  }
}
@media (max-width: 336.98px) {
  .search-col-div{
    padding: 0 18px;
  }
}

/* ! Combonent Style */

/* for debugging */
@keyframes react-widgets-autofill-start {
  from {
    /**/
  }

  to {
    /**/
  }
}

@keyframes react-widgets-autofill-cancel {
  from {
    /**/
  }

  to {
    /**/
  }
}

.rw-btn {
  padding         : 0;
  margin          : 0;
  border          : none;
  color           : inherit;
  box-shadow      : none;
  background      : none;
  font            : inherit;
  line-height     : inherit;
  -ms-touch-action: manipulation;
  touch-action    : manipulation;
  outline         : 0;
  display         : inline-flex;
  align-items     : center;
  justify-content : center;
  background-clip : padding-box;
  cursor          : pointer;
}

.rw-btn:focus {
  outline: none;
}

.rw-btn:disabled,
fieldset[disabled] .rw-btn,
.rw-state-disabled .rw-btn,
.rw-state-readonly .rw-btn {
  cursor: inherit;
}

fieldset[disabled] .rw-btn {
  pointer-events: none;
}

.rw-picker-caret,
.rw-picker-btn {
  color: #212529;
}

.rw-picker-btn:hover {
  background-color: #e9ecef;
}

.rw-picker-btn:active {
  background-color: #dee2e6;
}

.rw-picker-btn:disabled,
fieldset[disabled] .rw-picker-btn {
  background-color: #e9ecef;
}

.rw-input-addon,
.rw-input-addon.rw-picker-btn {
  border-bottom   : 1px solid var(--black-color);
  position        : absolute;
  left            : 0;
  right           : 0;
  z-index         : 7;
  height          : 100%;
  width           : 100%;
  background-color: #b2d8ff29;
}

.rw-input-addon,
.rw-input-addon.rw-picker-btn svg {
  position: absolute;
  right   : 9px;
}

[dir=rtl] .rw-input-addon,
[dir=rtl] .rw-input-addon.rw-picker-btn {
  border-left: none;

}

.rw-sr {
  position: absolute;
  width   : 1px;
  height  : 1px;
  margin  : -1px;
  padding : 0;
  overflow: hidden;
  clip    : rect(0, 0, 0, 0);
  border  : 0;
}

.rw-widget {
  border     : none;
  color      : #212529;
  font-size  : 1em;
  font-family: inherit;
  outline    : none;
  position   : relative;
}

.rw-widget,
.rw-widget *,
.rw-widget:after,
.rw-widget:before,
.rw-widget *:after,
.rw-widget *:before {
  box-sizing: border-box;
}

.rw-state-readonly,
.rw-state-disabled,
fieldset[disabled] .rw-widget {
  cursor: not-allowed;
}

.rw-widget-picker {
  display         : grid;
  overflow        : hidden;
  min-height      : 38px;
  background-color: #fff;

  outline      : none;
  grid-template: 1fr/1fr 1.9em;
  width        : 100%;
}

.rw-widget-picker.rw-hide-caret {
  grid-template-columns: 1fr;
}

.rw-state-focus .rw-widget-picker {
  transition: box-shadow 0.15s ease-in-out;
}

.rw-input {
  padding         : 0;
  margin          : 0;
  border          : none;
  color           : inherit;
  box-shadow      : none;
  background      : none;
  font            : inherit;
  line-height     : inherit;
  -ms-touch-action: manipulation;
  touch-action    : manipulation;
  outline         : 0;
  padding         : 0 0.857em;
}

.rw-input:disabled {
  opacity: 1;
}

.rw-input[type=text]::-ms-clear {
  display: none;
}

.rw-input:disabled,
.rw-input:read-only {
  cursor: inherit;
}

.rw-widget-input {
  background-color: #fff;
  font-size       : 0.92rem;
  color           : #828ca5;
  background-clip : padding-box;
  width           : inherit;
}

.rw-widget-input.rw-state-disabled,
.rw-state-disabled .rw-widget-input,
fieldset[disabled] .rw-widget-input {
  background-color: #e9ecef;
}

.rw-placeholder,
.rw-input::placeholder {
  color: #999;
}

.rw-state-disabled .rw-placeholder,
.rw-state-disabled .rw-input::placeholder {
  color: #999;
}

.rw-detect-autofill {
  padding         : 0;
  margin          : 0;
  border          : none;
  color           : inherit;
  box-shadow      : none;
  background      : none;
  font            : inherit;
  line-height     : inherit;
  -ms-touch-action: manipulation;
  touch-action    : manipulation;
  outline         : 0;
}

.rw-detect-autofill:disabled {
  opacity: 1;
}

.rw-detect-autofill:-webkit-autofill {
  animation-name    : react-widgets-autofill-start;
  animation-duration: 0.01ms;
}

.rw-detect-autofill:not(:-webkit-autofill) {
  animation-name    : react-widgets-autofill-cancel;
  animation-duration: 0.01ms;
}

.rw-webkit-autofill .rw-widget-container,
.rw-input:-webkit-autofill {
  background-color: #e8f0fe !important;
  background-image: none !important;
  color           : black !important;
}

.rw-list {
  font-size     : 1em;
  overflow-x    : visible;
  overflow-y    : auto;
  padding-top   : 0.5em;
  padding-bottom: 0.5em;
}

.rw-list:focus {
  outline: none;
}

.rw-list-option,
.rw-list-option-create {
  user-select    : none;
  color          : var(--black-color);
  cursor         : pointer;
  background-clip: padding-box;
}

.rw-list-option:hover,
.rw-list-option-create:hover,
[data-intent=keyboard] .rw-list-option.rw-state-focus:not(.rw-state-selected),
[data-intent=keyboard] .rw-state-focus.rw-list-option-create:not(.rw-state-selected) {
  background-color: #e9ecef;
  border-color    : #e9ecef;
}

.rw-list-option.rw-state-selected,
.rw-state-selected.rw-list-option-create {
  background-color: #007bff;
  border-color    : #007bff;
  color           : #fff;
}

.rw-list-option.rw-state-disabled,
.rw-state-disabled.rw-list-option-create {
  cursor          : not-allowed;
  background-color: transparent;
  border-color    : transparent;
  color           : #dee2e6;
}

.rw-list-empty,
.rw-list-option,
.rw-list-option-create,
.rw-list-optgroup {
  padding: 0.25em 1.5em;
  outline: none;
}

.rw-list-empty {
  text-align: center;
  color     : #ced4da;
}

.rw-list-optgroup {
  font-weight: bold;
  padding-top: 7px;
}

.rw-list-option-create {
  display  : block;
  font-size: 1em;
  padding  : 0.25em 1.5em;
}

.rw-listbox {
  /* border-radius: 4px; */
  background-color: #fff;

}

.rw-listbox.rw-state-focus {
  border-color : #80bdff;
  transition   : box-shadow 0.15s ease-in-out;
}

.rw-listbox.rw-state-disabled>*,
fieldset[disabled] .rw-listbox>* {
  pointer-events: none;
}

.rw-listbox.rw-state-readonly>* {
  cursor: inherit;
}

.rw-popup-container {
  position: absolute;
  z-index : 2;
  top     : 100%;
  left    : -1em;
  right   : -1em;
  padding : 0 1em;
}

.rw-popup-container.rw-dropup {
  top   : auto;
  bottom: 100%;
}

.rw-state-focus .rw-popup-container {
  z-index: 2;
}

.rw-slide-transition {
  margin-bottom: 1em;
}

.rw-dropup>.rw-slide-transition {
  margin-bottom: 0;
  margin-top   : 1em;
}

.rw-popup {
  overflow                  : auto;
  -webkit-overflow-scrolling: touch;
  margin-top                : 2px;
  box-shadow                : 0 0.5em 1em rgba(0, 0, 0, 0.175);

  background-clip: padding-box;
  background     : #fff;
}

.rw-popup .rw-list {
  max-height: 250px;
}

.rw-dropup .rw-popup {
  margin-top   : 0;
  margin-bottom: 2px;
  box-shadow   : 0 0.5em 1em rgba(0, 0, 0, 0.175);
}

.rw-slide-transition {
  transition: transform 130ms, opacity 100ms;
}

.rw-slide-transition-entering {
  overflow: hidden;
}

.rw-slide-transition-entering .rw-slide-transition {
  transform                 : translateY(0);
  opacity                   : 1;
  transition-timing-function: ease-out;
}

.rw-slide-transition-exiting .rw-slide-transition {
  transition-timing-function: ease-in;
}

.rw-slide-transition-exiting,
.rw-slide-transition-exited {
  overflow: hidden;
}

.rw-slide-transition-exiting .rw-slide-transition,
.rw-slide-transition-exited .rw-slide-transition {
  opacity  : 0;
  transform: translateY(-10%);
}

.rw-slide-transition-exiting.rw-dropup .rw-slide-transition,
.rw-slide-transition-exited.rw-dropup .rw-slide-transition {
  opacity  : 0;
  transform: translateY(10%);
}

.rw-slide-transition-exited {
  display: none;
}

.rw-dropdown-list {
  width: 100%;
}

.rw-dropdownlist-search {
  padding         : 0;
  margin          : 0;
  border          : none;
  color           : inherit;
  box-shadow      : none;
  background      : none;
  font            : inherit;
  line-height     : inherit;
  -ms-touch-action: manipulation;
  touch-action    : manipulation;
  outline         : 0;
}

.rw-dropdownlist-search:disabled {
  opacity: 1;
}

.rw-dropdown-list-input {
  padding      : 0 0.857em;
  align-self   : center;
  display      : grid;
  min-width    : 0;
  grid-template: 1fr/1fr;
}

.rw-dropdown-list-input>* {
  grid-area    : 1/1;
  text-overflow: ellipsis;
  white-space  : nowrap;
  overflow     : hidden;
}

[dir=rtl] .rw-dropdown-list-input {
  padding-right: 0.857em;
  padding-left : 0;
}

.rw-combobox-input {
  z-index            : 0;
  border-bottom      : 1px solid var(--black-color);
  padding-right      : 1re;
}

.rw-multiselect-input {
  padding         : 0;
  margin          : 0;
  border          : none;
  color           : inherit;
  box-shadow      : none;
  background      : none;
  font            : inherit;
  line-height     : inherit;
  -ms-touch-action: manipulation;
  touch-action    : manipulation;
  outline         : 0;
  height          : calc(38px - 1px * 2);
  padding         : 0 0.857em;
  max-width       : 100%;
}

.rw-multiselect-input:disabled {
  opacity: 1;
}

.rw-multiselect-input:disabled,
.rw-multiselect-input:read-only {
  cursor: inherit;
}

.rw-multiselect-taglist {
  display    : flex;
  flex-wrap  : wrap;
  align-items: flex-start;
  width      : 100%;
  outline    : none;
  cursor     : text;
}

.rw-state-disabled .rw-multiselect-taglist {
  cursor: unset;
}

.rw-multiselect-tag {
  display         : inline-flex;
  margin-left     : calc(38px * 0.115 - 1px);
  margin-top      : calc(38px * 0.115 - 1px);
  min-height      : calc(38px * 0.77);
  border-radius   : 3px;
  background-color: #dee2e6;
  cursor          : default;
  max-width       : 100%;
  align-items     : center;
  overflow        : hidden;
}

.rw-multiselect-tag:focus {
  outline: none;
}

[dir=rtl] .rw-multiselect-tag {
  margin-left : 0;
  margin-right: calc(38px * 0.115 - 1px);
  padding     : 0 0.35em 0 0.35em;
}

.rw-multiselect-tag.rw-state-focus {
  z-index      : 1;
  transition   : box-shadow 0.15s ease-in-out;
}

.rw-multiselect-tag.rw-state-disabled,
fieldset[disabled] .rw-multiselect-tag {
  opacity: 0.65;
}

.rw-multiselect-tag-label {
  padding: 0 0.35em 0 0.35em;
}

.rw-multiselect-tag-btn {
  padding         : 0;
  margin          : 0;
  border          : none;
  color           : inherit;
  box-shadow      : none;
  background      : none;
  font            : inherit;
  line-height     : inherit;
  -ms-touch-action: manipulation;
  touch-action    : manipulation;
  outline         : 0;
  cursor          : pointer;
  align-self      : stretch;
  padding         : 0 0.35em;
}

.rw-multiselect-tag-btn:focus {
  outline: none;
}

.rw-multiselect-tag-btn:disabled {
  cursor: inherit;
}

.rw-calendar-popup {
  right    : auto;
  min-width: 0;
  width    : 22em;
}

.rw-calendar {
  overflow: hidden;
}

.rw-calendar-contained {
  background-color: #fff;

}

.rw-calendar-now {
  font-weight: bold;
}

.rw-calendar-header {
  display: flex;
  padding: 0.8em;
}

.rw-cell,
.rw-calendar-btn {
  background-clip: padding-box;
}

.rw-cell:focus,
.rw-calendar-btn:focus {
  z-index      : 1;
  transition   : box-shadow 0.15s ease-in-out;
}

[data-intent=mouse] .rw-cell:focus,
[data-intent=mouse] .rw-calendar-btn:focus {
  z-index   : auto;
  box-shadow: none;
}

.rw-cell:disabled,
.rw-cell.rw-state-disabled,
.rw-calendar-btn:disabled,
.rw-calendar-btn.rw-state-disabled {
  pointer-events: none;
  opacity       : 0.35;
}

.rw-calendar-btn {
  line-height     : 2em;
}

.rw-calendar-btn:hover {
  background-color: #e9ecef;
}

.rw-calendar-btn:active {
  background-color: #dee2e6;
}

.rw-calendar-btn-left,
.rw-calendar-btn-today,
.rw-calendar-btn-right,
.rw-calendar-btn-view {
  padding: 0.3em 0.6em;
}

[dir=rtl] .rw-calendar-btn-left,
[dir=rtl] .rw-calendar-btn-right {
  transform: scale(-1, 1);
}

.rw-calendar-btn-view {
  font-weight: bold;
}

.rw-calendar-btn-view> :last-child {
  margin: 0 0.5em;
}

.rw-calendar-btn-view+* {
  margin-left: auto;
}

[dir=rtl] .rw-calendar-btn-view+* {
  margin-left : 0;
  margin-right: auto;
}

.rw-calendar-grid {
  display         : table;
  outline         : none;
  height          : 14em;
  padding         : 3px;
  table-layout    : fixed;
  border-collapse : separate;
  border-spacing  : 0;
  width           : 100%;
  background-color: #fff;
}

.rw-calendar-head {
  display: table-header-group;
}

.rw-calendar-body {
  display: table-row-group;
}

.rw-calendar-row {
  display: table-row;
}

.rw-head-cell {
  display         : table-cell;
  text-align      : center;
  padding         : 0.25em;
}

.rw-cell {
  display         : table-cell;
  color           : #212529;
  vertical-align  : middle;
  cursor          : pointer;
  line-height     : normal;
  text-align      : center;
  padding         : 0.25em;
  outline         : none;
}

.rw-cell:hover {
  background-color: #e9ecef;
}

.rw-cell:active {
  background-color: #dee2e6;
}

.rw-cell.rw-state-selected {
  background-color: #007bff;
  border-color    : #007bff;
  color           : #fff;
}

.rw-calendar-month .rw-cell {
  text-align: center;
}

.rw-cell-off-range {
  color: #ced4da;
}

.rw-calendar-transition-group {
  position: relative;
  overflow: hidden;
}

.rw-calendar-transition {
  width     : 100%;
  overflow  : hidden;
  transition: transform 0.2s ease-in-out 0ms;
  transform : translate(0, 0);
}

@supports (transform-style: preserve-3d) {
  .rw-calendar-transition {
    transform: translate3d(0, 0, 0);
  }
}

.rw-calendar-transition-next {
  -webkit-backface-visibility: hidden;
  backface-visibility        : hidden;
}

.rw-calendar-transition-top .rw-calendar-transition-next {
  transform: translate(0, -100%);
}

@supports (transform-style: preserve-3d) {
  .rw-calendar-transition-top .rw-calendar-transition-next {
    transform: translate3d(0, -100%, 0);
  }
}

.rw-calendar-transition-bottom .rw-calendar-transition-next {
  transform: translate(0, 100%);
}

@supports (transform-style: preserve-3d) {
  .rw-calendar-transition-bottom .rw-calendar-transition-next {
    transform: translate3d(0, 100%, 0);
  }
}

.rw-calendar-transition-right .rw-calendar-transition-next {
  transform: translate(-100%, 0);
}

@supports (transform-style: preserve-3d) {
  .rw-calendar-transition-right .rw-calendar-transition-next {
    transform: translate3d(-100%, 0, 0);
  }
}

.rw-calendar-transition-left .rw-calendar-transition-next {
  transform: translate(100%, 0);
}

@supports (transform-style: preserve-3d) {
  .rw-calendar-transition-left .rw-calendar-transition-next {
    transform: translate3d(100%, 0, 0);
  }
}

.rw-calendar-transition-next.rw-calendar-transition-active {
  transform: translate(0, 0);
}

@supports (transform-style: preserve-3d) {
  .rw-calendar-transition-next.rw-calendar-transition-active {
    transform: translate3d(0, 0, 0);
  }
}

.rw-calendar-transition-prev {
  -webkit-backface-visibility: hidden;
  backface-visibility        : hidden;
  position                   : absolute;
  left                       : 0;
  top                        : 0;
}

.rw-calendar-transition-top .rw-calendar-transition-prev {
  transform: translate(0, 100%);
}

@supports (transform-style: preserve-3d) {
  .rw-calendar-transition-top .rw-calendar-transition-prev {
    transform: translate3d(0, 100%, 0);
  }
}

.rw-calendar-transition-bottom .rw-calendar-transition-prev {
  transform: translate(0, -100%);
}

@supports (transform-style: preserve-3d) {
  .rw-calendar-transition-bottom .rw-calendar-transition-prev {
    transform: translate3d(0, -100%, 0);
  }
}

.rw-calendar-transition-right .rw-calendar-transition-prev {
  transform: translate(100%, 0);
}

@supports (transform-style: preserve-3d) {
  .rw-calendar-transition-right .rw-calendar-transition-prev {
    transform: translate3d(100%, 0, 0);
  }
}

.rw-calendar-transition-left .rw-calendar-transition-prev {
  transform: translate(-100%, 0);
}

@supports (transform-style: preserve-3d) {
  .rw-calendar-transition-left .rw-calendar-transition-prev {
    transform: translate3d(-100%, 0, 0);
  }
}

.rw-popup {
  display       : flex;
  flex-direction: column;
}

.rw-popup>.rw-time-input {
  align-self: center;
  margin    : 1em 0;
}

.rw-time-input {
  display         : inline-flex;
  min-height      : 38px;
  align-items     : center;
  background-color: #fff;
  outline: none;
  cursor : text;
}

.rw-time-part-input {
  padding         : 0;
  margin          : 0;
  border          : none;
  color           : inherit;
  box-shadow      : none;
  background      : none;
  font            : inherit;
  line-height     : inherit;
  -ms-touch-action: manipulation;
  touch-action    : manipulation;
  outline         : 0;
  padding         : 0 0.5em;
}

.rw-time-part-input:disabled {
  opacity: 1;
}

.rw-time-part-input,
.rw-time-part-input::placeholder {
  text-align: center;
}

.rw-time-part-meridiem {
  padding         : 0;
  margin          : 0;
  border          : none;
  color           : inherit;
  box-shadow      : none;
  background      : none;
  font            : inherit;
  line-height     : inherit;
  -ms-touch-action: manipulation;
  touch-action    : manipulation;
  outline         : 0;
  padding         : 0 0.5em;
  text-transform  : lowercase;
  font-variant    : small-caps;
}

.rw-time-part-meridiem :focus {
  outline: none;
}

.rw-time-input-clear {
  padding    : 0 0.4em;
  outline    : none;
  opacity    : 0;
  margin-left: auto;
}

[dir=rtl] .rw-time-input-clear {
  margin-left : revert;
  margin-right: auto;
}

.rw-time-input:hover .rw-time-input-clear.rw-show,
.rw-time-input.rw-state-focus .rw-time-input-clear.rw-show {
  opacity: 1;
}

.rw-number-picker-spinners {
  display       : flex;
  flex-direction: column;
}

.rw-number-picker-spinners .rw-btn {
  flex: 1 1 0;
}
.datas-loading {

  background-color: transparent;
  border          : 5px solid rgb(234, 234, 234);
  height          : 50px;
  width           : 50px;
  border-radius   : 50%;
  border-top      : 5px solid transparent;
  animation       : kenarlik 0.7s linear infinite;
}

@keyframes kenarlik {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loading-piece {
  transition: 0.1s ease;
}

.loading-panel {
  position: relative;
}

.loading-icon {
  position       : absolute;
  left           : 0;
  right          : 0;
  bottom         : 0;
  top            : 0;
  display        : flex;
  align-items    : center;
  justify-content: center;
  background-size: 100% 100%;
}

.search-wrapper {
  padding : 48px 0 0;
  margin  : 0;
  position: relative;
}

.search-container {
  padding: 0 16px;
}

.search-container i {
  font-size       : 15px;
  color           : #fff;
  cursor          : pointer;
  position        : absolute;
  right           : 0;
  top             : 45px;
  z-index         : 9;
  background-color: #112950;
  padding         : 13px 14px 13px;
  transition      : 0.1s ease;
}

.search-input-box input {
  position        : absolute;
  right           : 0px;
  top             : 45px;
  border          : none;
  outline         : none;
  padding         : 8px 8px 7px 12px;
  border-radius   : 8px;
  background-color: #fff;
  color           : #112950;
  font-size       : 17px;
  font-weight     : 500;
  transition      : 0.3s ease;
}

.warning-write {
  position       : absolute;
  left           : 0;
  right          : 16px;
  top            : 0;
  display        : flex;
  justify-content: center;
  padding        : 0 0 0 1rem;
}

.warning-wrapper {
  position       : relative;
  border-radius  : 8px;
  width          : 100%;
  display        : flex;
  justify-content: stretch;
  align-items    : center;
}

.section-write {
  font-size  : 17px;
  font-weight: bolder !important;
}

.alertMesage {
  background-color: white !important;
}

.info-box {
  display    : flex;
  align-items: center;
}

.info-box i {
  color: #0a519c;
}
.form-select{
  background-color: #f6fbff;
}
.form-select:focus {
  border: none;
  border-top: 0;
  border-left: 0;
  border-left: 0;
  outline: 0;
  box-shadow: 0 0 0 0.1rem  #e5f2ff;
}
