.main-footer {
    background-color: #112950;
    padding: 36px;
    font-family: sans-serif;
}

.footer-icon-wrapper {
    display: flex;
    justify-content: center;
}

.footer-p-wrapper {
    padding: 18px 0 8px;
}

.footer-p-wrapper p {
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}

.footer-icon-container {
    display: flex;
    justify-content: center;
}

.phone-box {
    color: #FFFFFF;
    margin-right: 12px;
}

.mail-box {
    color: #FFFFFF;
    margin-left: 12px;
}

@media(max-width:1199.98px) {
    .main-footer {
        padding: 28px;
    }

    .footer-p-wrapper {
        padding: 20px 0 8px;
    }
}

@media(max-width:991.98px) {
    .main-footer {
        padding: 24px;
    }

    .footer-p-wrapper {
        padding: 15px 0 7px;
    }
}

@media(max-width:767.98px) {
    .main-footer {
        padding: 20px;
    }

    .footer-p-wrapper {
        padding: 10px 0 5px;
    }
}

@media(max-width:575.98px) {
    .main-footer {
        padding: 18px;
    }

    .footer-p-wrapper {
        padding: 10px 0 5px;
    }
}

@media(max-width:405.98px) {
    .main-footer {
        padding: 18px 4px;
    }
}
@media(max-width:385.98px) {
    .footer-icon-container{
        display: block;
    }
    .phone-box{
        text-align: center;
        margin-top: 2px;
    }
    .mail-div{
        display: flex;
        justify-content: center;
        margin-top: 2px;
    }
}