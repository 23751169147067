.allQuestions {
    padding    : 0;
    width      : 112px;
    font-weight: 600;
    color      : var(--threed-color);
    font-size  : 16px;
}

@media (max-width: 1199.98px) {
    .allQuestions {
        padding-left: 8px;
    }
}

.linkActive {
    color      : var(--threed-color);
    font-weight: 500;
}