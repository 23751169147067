@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@100;300;400;500;600&display=swap');

.App {
  font-family: Epilogue, sans-serif;
  /* text-align: center; */
}

.container-width {
  max-width: 1080px;
  margin: 0 auto;
  height: 100%;
}

.main-item:nth-child(odd) {
  background-color: var(--main-color);
}

#home {
  position: relative;
}

#home::before {
  content: "";
  background-image: url('./component/assets/header_background.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: .5;
  z-index: -1;
  background-position: center 75%;
  background-attachment: scroll;
}

#allQuestions {
  padding-bottom: 52px;
}

#allVideos,
#allQuestions {
  background: var(--main-color);
}


#allVideos {
  position: relative;
}

#allVideos::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .2);
  border-radius: 10px;
}

#allVideos::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--threed-color);
}


#socialChannels,
#videos,
#home,
#contacts,
#reviews,
#services,
#about {
  padding: 100px 0;
  width: 100%;
}

#applicationsMade {
  padding-bottom: 100px;
  width: 100%;
}

.containerStyle {
  width: 100%;
  height: 360px
}

.maps-container {
  height: 360px;
}

@media (max-width: 991.98px) {
  .maps-container {
    height: 250px;
  }

  #home {
    padding: 80px 0;
  }

  .search-container i {
    top: 8px !important;
  }

  .search-input-box input {
    top: 8px !important;
  }
}

@media (max-width: 767.98px) {
  #home {
    padding: 50px 0;
  }

  .search-wrapper {
    padding: 60px 0 0 !important;
  }

  .search-container i {
    top: 18px !important;
  }

  .search-input-box input {
    top: 18px !important;
  }

  #home::before {
    background-size: auto 100%;
  }
}

@media (max-width: 575.98px) {

  #socialChannels,
  #videos,
  #home,
  #contacts,
  #reviews,
  #services,
  #about {
    padding: 50px 0;
  }

  .space {
    height: 50px;
  }

  .home-item {
    position: relative;
    top: 6px;
  }

  .quote:last-child {
    margin-block-end: 0;
  }

  .services-title {
    padding: 36px 0 0 16px !important;
    font-size: 25px !important;

  }

  .social-div {
    display: flex;
    justify-content: center;
  }
}