#write-container {
    margin-inline-start: 38px;
    padding: 0;
}

#home-img {
    transform       : scale(0.8);
    transform-origin: top right;
    cursor          : auto;
}

.home-title {
    font-size  : 50px !important;
    text-align : start;
    font-weight: 600 !important;
    color      : var(--black-color);
}

.home-link {
    font-size: 30px !important;
    margin   : 12.8625px 0 0;
}

.telephone-div {
    display: flex;
    margin : 26.3375px 0px -12.25px 0px;
}

.telephone-item {
    height          : 70px;
    width           : 70px;
    background-color: var(--second-color);
    display         : flex;
    justify-content : center;
    align-items     : center;
    border-radius   : 50px;
}

.telephone-body {
    margin: 0px 0px 12.25px 17.5px;
}

.telephone-body a {
    font-size  : 31.5px;
    font-weight: 900;
}

.telephone-body a:hover {
    color: #20be00;
    transition: 0.1s ease
}

.telephone-text {
    font-size  : 15px;
    margin     : 0;
    color      : gray;
    font-weight: 300;
}

.telephone-adress {
    font-size  : 16px;
    margin     : 9px 0 0 0;
    font-weight: 500;
    padding-left:87.5px ;
}

#home-img {
    display         : block;
    margin          : auto;
    background-color: hsl(0, 0%, 90%);
    transition      : background-color 300ms;
    width           : 100%;
}

#img-container {
    padding-bottom: 2rem;
    box-sizing: border-box;
    padding-right: 38px;
}

.blurb-icon {
    transition: all 0.2s ease-in-out;
}

.icon {
    display    : inline-flex;
    line-height: 1;
}

.blurb-title h3 {
    font-size: 21px;
    margin   : 0;
    color    : var(--black-color);
}

.blurb-text {
    font-size: 14px;
    color    : #828ca5;
    margin   : 11.6px 0 0 0;
}
.space {
    height          : 100px;
    width           : 100%;
    background-color: var(--second-color);
}
.telephone-item img {
    height: 48px;
    width : 48px;
    cursor: pointer;
}
@media (max-width: 1450.98px) {
    .home-title {
        font-size: 49px !important;
    }

    .home-link {
        font-size: 26px !important;
        margin   : 12.8625px 0 0;
    }
    #img-container {
        padding-right: 30px;
    }
    

}
@media (max-width: 1400.98px) {
    .home-title {
        font-size: 46px !important;
    }

    .home-link {
        font-size: 25px !important;
        margin   : 12.8625px 0 0;
    }

}
@media (max-width: 1350.98px) {
    .home-title {
        font-size: 45.6px !important;
    }

    .home-link {
        font-size: 25px !important;
        margin   : 12.8625px 0 0;
    }

}
@media (max-width: 1300.98px) {
    .home-title {
        font-size: 45.6px !important;
    }

    .home-link {
        font-size: 25px !important;
        margin   : 12.8625px 0 0;
    }

}
@media (max-width: 1199.98px) {
    #write-container {
        margin-inline-start: 0;
    }

    .home-title {
        font-size: 41.6px !important;
    }

    .home-link {
        font-size: 26px !important;
        margin   : 12.8625px 0 0;
    }

    .telephone-div {
        display: flex;
        margin : 26.3375px 0px -12.25px 0px;
    }

    .telephone-body a {
        font-size: 25.2px;
    }
    .telephone-item{
        width: 56px;
        height: 56px;
    }
    .telephone-item img {
        height: 38px;
        width : 38px;
        cursor: pointer;
    }
    .telephone-adress {
        font-size  : 16px;
        padding-left: 73.5px;
    }

}


@media (max-width: 991.98px) {

    .telephone-item {
        height: 66.75px;
        width : 66.75px;
    }

    .telephone-item img {
        height: 43.5px;
        width : 43.5px;
    }

    #home-img {
        transform       : scale(1);
        transform-origin: center;
    }

    #home-bottom {
        margin-top: 3rem;
    }
    .home-title {
        font-size  : 26px !important;
    }
    
    .home-link {
        font-size: 18px !important;
    }
    .telephone-adress {
        padding-left: 64.25px;
    }
}
@media(max-width:767.98px){

    .home-title {
        font-size  : 34px !important;
        text-align: center;
    }
    
    .home-link {
        font-size: 22px !important;
        text-align: center;
    }
    .telephone-div{
        justify-content: center;
    }
    .telephone-text {
        font-size  : 13px;
        padding-bottom: 10px;
    }
    .tel-item-wrapper{
        display: flex;
        justify-content: center;
        padding-bottom: 15px;
    }

    .telephone-item {
        height: 68px;
        width : 68px;
    }

    .telephone-item img {
        height: 43px;
        width : 43px;
    }
    .telephone-adress {
        text-align: center;
        padding: 0;
        padding-bottom: 36px;
    }
    .telephone-div {
        display: block;
        justify-content: center;
        text-align: center;
    }
    .telephone-body {
        margin: 0;
    }
}
@media (max-width: 575.98px) {
    .home-title {
        font-size: 26px !important;
    }
    .text-primary{
        font-size: 18px !important;
    }
    #img-container {
        padding-right:12px;
    }
}
@media (max-width: 351.98px) {
    .home-title {
        font-size: 22px !important;
    }
    .text-primary{
        font-size: 15px !important;
    }
}


