.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 600px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}


@media (max-width: 991.98px) {
  .swiper-slide {
    width: 500px;
  }

  .swiper-slide {
    width: 300px;
  }

  .swiper-button-next:after {
    position: absolute;
    right: 50px !important;


    padding: 12px 18px !important;
    font-size: 24px !important;

  }

  .swiper-button-prev:after {
    position: absolute;
    left: 50px !important;

    padding: 12px 18px;
    font-size: 24px;

  }
}

@media (max-width: 767.98px) {
  .swiper-slide {
    width: 300px;
  }

  .swiper-button-next:after {
    position: absolute;
    right: 20px !important;


    padding: 7px 11px !important;
    font-size: 15px !important;

  }

  .swiper-button-prev:after {
    position: absolute;
    left: 20px !important;

    padding: 7px 11px !important;
    font-size: 15px !important;

  }
}

@media (max-width: 575.98px) {
  .swiper-slide {
    width: 300px;
  }

  .swiper-button-next:after {
    position: absolute;
    right: 0px !important;
    padding: 7px 11px !important;
    font-size: 15px !important;
  }

  .swiper-button-prev:after {
    position: absolute;
    left: 0px !important;
    padding: 7px 11px !important;
    font-size: 15px !important;

  }
}

.swiper-button-next:after {
  position: absolute;
  right: 40px;
  background-color: rgba(21, 132, 236, 0.582);

  padding: 12px 18px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 700;
  color: white;
}

.swiper-button-prev:after {
  position: absolute;
  left: 40px;
  background-color: rgba(21, 132, 236, 0.582);

  padding: 12px 18px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 700;
  color: white;
}