@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@100;300;600&display=swap');

.navActive {
    display         : flex;
    align-items     : center;
    position        : -webkit-sticky;
    position        : sticky;
    top             : 0px;
    height          : 140px;
    min-height      : 60px;
    z-index         : 99;
    background-color: rgb(255, 255, 255);
    width           : 100%;
    transition      : height 0.2s, box-shadow 0.2s;
    align-items     : center;
    box-shadow      : 0 0 28px rgba(0, 0, 0, 0.093);

}

.nav {
    transition      : height 0.2s, box-shadow 0.2s;
    display         : flex;
    align-items     : center;
    position        : -webkit-sticky;
    height: 140px;
    width: 100%;
    position        : sticky;
    top             : 0px;
    z-index         : 99;
    /* height          : 104px; */
    min-height      : 60px;
    z-index         : 999;
    background-color: #fff;
    margin          : 0 auto;
    box-shadow      : 0 0 28px rgba(0, 0, 0, 0.062);

}

.nav__container {
    display        : flex;
    flex-flow      : row nowrap;
    align-items    : center;
    justify-content: space-between;
    height         : 100%;
    margin         : 0 auto;
    position       : relative;
}

.navbar>.container-fluid {
    width: 1103px;
    height: 100%;
}

.nav .container-fluid {
    position: relative;
    bottom  : 6px;
}

.logo {
    padding : 0 23px 0 0;
    position: relative;
    top     : 6px;
}

.logo img {
    width: 70px;
}

.navbar-spaces {
    width: 252px;
}
/* navbar spaces  */


Nav ul {
    display    : flex;
    list-style : none;
    padding    : 0;
    align-items: center;
    position   : relative;
    top        : 7px;
    margin     : 0;
}

Nav li {
    align-items: center;
    margin     : 0 6px;
    display    : flex;
    align-items: center;
    /* width      : calc(100% + 5px); */
    padding    : 0;
}

Nav li:last-child {
    padding    : 0;
    width      : auto;
    margin-left: 12px;
}

Nav li a {
    font-size  : 14px;
    font-family: 'Epilogue', sans-serif;
    font-weight: 400;
    height     : auto;
    padding    : 0;
}

a {
    color          : var(--black-color);
    text-decoration: none;
}

.position-before {
    position: sticky;
    top     : 15px;
}

#menu-icon-div {
    border   : none;
    padding  : 0 2rem;
    font-size: 1.5rem;
}

#allQuestionsBtn,
#make {
    position      : relative;
    bottom        : 1rem;
    padding       : 0.3rem 0.9rem;
}

button:focus:not(:focus-visible) {
    outline: 0;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline        : 0;
    box-shadow     : none;
}

.dot-modal {
    display         : flex;
    justify-content : center;
    align-items     : center;
    padding         : 2rem;
    background-color: blueviolet;
    position        : absolute;
    right           : 0;
    top             : 130px;
    background-color: #fff;
}

#make,
#dotMake {
    height         : 50px;
    width          : 170px;
    padding        : 0;
    display        : flex;
    justify-content: center;
    align-items    : center;
    margin-top: 8px;
}

#dotMake {
    margin: 0;

}

.setting-icon {
    display       : flex;
    position      : absolute;
    top           : calc(50% - 28px);
    right         : 3rem;
    z-index       : 99999;
    flex-direction: column;
    height        : 54px;
    width         : 10px;
    font-weight   : 600;
    font-size     : 1.8rem;
    color         : var(--black-color);
    cursor        : pointer;
    transform     : 0.2s ease;
    align-items   : center;
    transition    : 0.3s ease;
    transform     : rotateZ(0deg);
}

.closeButton {
    transform      : rotateZ(45deg);
    color          : var(--black-color);
    font-size      : 1.5rem;
    display        : flex;
    justify-content: center;
    align-items    : center;
    width          : inherit;
    height         : inherit;
}

.rotate-dot {
    transition: 0.3s ease;
    transform : rotateZ(-45deg);
}

#setting-icon-item-0 {
    position: absolute;
    top     : -7px;
}

#setting-icon-item-1 {
    position: absolute;
    top     : 7px;
}

#setting-icon-item-2 {
    position: absolute;
    top     : 0px;
}

.menu-icon {
    cursor: pointer;
}

.nav-link {
    color      : var(--black-color);
    padding    : 0;
    cursor     : pointer;
    font-weight: 500;
}

.nav-link:hover,
.active:hover {
    color: var(--threed-color);
}

.navbar-nav .nav-link.active {
    color: var(--black-color);
}

.linkActive {
    color      : var(--threed-color);
    font-weight: 500;
}
.navbar-media-container{
    display: flex;
    justify-content: end;
}
.social-header {
    display: none;
}

@media (max-width: 1199.98px) {
    .navbar-media-container{
        display: none;
    }
    nav {
        /* position: fixed;
        top: 0; */
        width: 100%;
    }
    
    .logo {
        display : flex;
        position: absolute;
        left    : calc(50% - 90px);
        top     : 24px;
    }

    .logo img {
        width: 60px;
    }
    .social-header img{
        width: 35px;
    }
    .navActive {
        position: sticky;
        top: 0;
        height: 140px;
    }
    .social-header{
        display: block;
    }

    #menu-icon-div {
        position       : absolute;
        top            : calc(50% + 25px);
        display        : flex;
        justify-content: flex-start;
    }
    #rotate-icons{
        position       : absolute;
        top            : calc(50% + 0px);
        display        : flex;
        justify-content: flex-start;
    }

    .offcanvas {
        box-shadow      : rgb(130 140 165 / 10%) 0px 2px 0px 0px inset;
        background-color: #fff;
    }

    .offcanvas.offcanvas-end {
        width    : 300px;
        top      : 140px;
        left     : 0;
        transform: translateX(-300px);
    }

    .offcanvas ul {
        position      : relative;
        bottom        : 0;
        display       : flex;
        flex-direction: column;
        align-items   : center;
        z-index       : 999;

    }

    .offcanvas ul li {
        list-style   : none;
        color        : var(--black-color);
        font-weight  : 500;
        border-bottom: 1px solid #e5e5e5;
        width        : 100%;
    }

    .offcanvas ul li a {
        font-size  : 20.44px;
        font-weight: 400;
        width      : 100%;
        padding    : 14px 30px;

    }

    .offcanvas-body {
        padding   : 0;
        overflow-y: auto;
        flex-grow : 1;
        z-index   : 99;
        box-shadow: rgb(130 140 165 / 10%) 0px 0px 12px;
    }

    .offcanvas-backdrop {
        display: none;
    }

    #make {
        display: none;
    }

    .offcanvas ul li:last-child {
        visibility: hidden;
    }
}
@media(max-width: 991.98px){
    .logo {
        left    : calc(50% - 80px);
    }
}
@media(max-width: 767.98px){
    .logo {
        left    : calc(50% - 60px);
    }
}
@media(max-width: 575.98px){
    .logo {
        left    : calc(50% - 62px);
    }
}