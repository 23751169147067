.main-piece-container{
    background-color: #f6f9fd ;
    border-radius: 16px;
    padding: 24px 24px;
    min-height: 420px;
}
.img-container-wrapper{
    display: flex;
    justify-content: center;
    padding-top: 36px;
}
.image-container{
    
    display: inline-block;
    background-color: #FFFFFF;
    padding: 4px;
    border-radius: 32px;
    
}
.title-container{
    display: flex;
    justify-content: center;
    padding: 24px 0;
    text-align: center;
}
.title-box{
    font-size: 21px;
    color: var(--black-color);
    margin: 0;
}
.write-box{
    text-align: center;
    color: #828ca5;
    font-size: 14px;
}
@media(max-width:991.98px){
    .main-piece-container{
        padding: 24px 16px;
    }
    .title-box{
        font-size: 18px;
    }
    .title-container{
        padding: 16px 0;
    }
}
@media(max-width:767.98px){
    .title-box{
        font-size: 20px;
    }
}
@media(max-width:575.98px){
    .main-piece-container{
        margin: 0 48px;
    }
    .title-box{
        font-size: 19px;
    }
}
@media(max-width:351.98px){
    .main-piece-container{
        margin: 0 32px;
    }
    .title-box{
        font-size: 17px;
        font-weight: 600;
    }
}